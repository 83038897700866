@media (min-width: 768px) {
  .container-badges {
      padding-bottom: 60px;
  }
}

@media (max-width: 768px) {
    .container-badges {
        padding-bottom: 0px;
    }
}
