:root {
  --primary-color: #0C71C3;
  --primary-dark-color: #005EAD;
  --primary-dark-color-alpha: rgba(2, 7, 207, 0.5);

  --hover-color: #67A8FF;

  --secondary-color: #349690;
  --secondary-dark-color: #265F5B;

  --yellow-color: #DAA21B;
  --yellow-color-alpha: rgba(254, 209, 54, 0.9);

  --dark-background-color: #212529;
  --light-background-color: #f4f3ef;

  --navbar-content-color: #f4f3ef;
  --navbar-background-color: #212529;

  --discrete-color: #66615B;
}
