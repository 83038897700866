.badge-element {
    padding-top: 0px;
    text-align: center;
    max-width: 50%;
    margin: auto;
}

.badge-title {
    padding-top: 15px;
    text-align: center;
    margin: auto;
    font-family: Montserrat;
    font-weight: bold;
}
