

@media (max-width: 768px) {
    .container-press-badges {
        padding-bottom: 50px;
    }

    .press-badge-element {
        max-width: 80px;
        padding-top: 0px;
        text-align: center;
        margin: auto;
        box-shadow : 0 3px 15px 2px rgba(0,0,0,0.3);
    }
}

@media (min-width: 768px) {
    .press-badge-element {
        max-width: 150px;
        padding-top: 0px;
        text-align: center;
        margin: auto;
        box-shadow : 0 3px 15px 2px rgba(0,0,0,0.3);
    }
}

.press-badge-title {
    padding-top: 15px;
    text-align: center;
    margin: auto;
    font-family: Montserrat;
    font-weight: bold;
}
